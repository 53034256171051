<template>
  <div class="wallet-detail-modal">
    <div class="container">
      <div class="shop-info">
        <div class="row align-items-center">
          <div class="col-4">
            <b-img class="w-100" thumbnail fluid :src="wallet.businessAccount.logoImg" :alt="wallet.businessAccount.name"></b-img>
          </div>

          <div class="col">
            <h5 class="font-weight-bold mb-2">{{ wallet.businessAccount.name }}</h5>
            <p class="mb-0">已售出單品：共{{ soldClothCount }}件</p>
          </div>
        </div>
      </div>

      <div class="return-price-info">
        <p class="text-secondary text-center mb-1">回饋金總額</p>
        <p class="return-price-sum">NTD {{ soldClothPriceSum }}</p>

        <div class="return-price-detail">
          <div class="row justify-content-between mb-0">
            <div class="col-auto">已領</div>
            <div class="col-auto">NTD {{ withdrawSum }}</div>
          </div>

          <div class="row justify-content-between mb-0">
            <div class="col-auto">處理中</div>
            <div class="col-auto">NTD {{ withdrawProcessingSum }}</div>
          </div>

          <div class="row justify-content-between mb-0">
            <div class="col-auto">可提領</div>
            <div class="col-auto">NTD {{ wallet.points - withdrawProcessingSum }}</div>
          </div>
        </div>
      </div>

      <div class="log-list">
        <label for="" class="font-weight-bold">提領紀錄</label>

        <WithdrawLogCard v-for="(log) in withdrawList" :key="log.serial" :log="log"></WithdrawLogCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WithdrawLogCard from './WithdrawLogCard.vue';

export default {
  name: 'WalletDetailModal',
  data() {
    return {
      readingBusyName: 'WALLETDETAILMODAL',
      
      withdrawList: [],
      sellClothList: [],
      init: false,
    };
  },
  props: {
    wallet: {
      type: Object,
      require: true,
    }
  },
  components: {
    WithdrawLogCard,
  },
  computed: {
    soldClothCount() {
      return this.sellClothList.reduce((sum, item) => {
        if (item.stockStatus === 1) {
          return sum + 1;
        }
        return sum;
      }, 0);
    },
    soldClothPriceSum() {
      return this.sellClothList.reduce((sum, item) => {
        if (item.stockStatus === 1) {
          return sum + item.soldReturn;
        }
        return sum;
      }, 0);
    },
    withdrawSum() {
      return this.withdrawList.reduce((sum, item) => {
        if (item.status === 1) {
          return sum + item.withdrawAmount;
        }
        return sum;
      }, 0);
    },
    withdrawProcessingSum() {
      return this.withdrawList.reduce((sum, item) => {
        if (item.status === 0) {
          return sum + item.withdrawAmount;
        }
        return sum;
      }, 0);
    }
  },
  watch: {
  },
  async mounted() {
    await this.readInfo();
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    async readInfo() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const withdrawList = await this.$store.dispatch('api/getWalletWithdrawListPromise');
        const sellClothList = await this.$store.dispatch('api/getSellClothListPromise');
        
        withdrawList.filter((w) => {
          return w.businessAccountSerial === this.wallet.businessAccount.serial;
        }).map((w) => {
          this.withdrawList.push(w);
        });
        sellClothList.filter((sc) => {
          return sc.businessAccountSerial === this.wallet.businessAccount.serial;
        }).map((sc) => {
          this.sellClothList.push(sc);
        });

        this.init = true;
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.wallet-detail-modal {
  padding: 1rem 0;
  .shop-info {
    margin-bottom: 1rem;
  }

  .return-price-info {
    border-radius: 1.5rem;
    background-color: #FFFAF4;
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    .return-price-sum {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }

    .return-price-detail {
      color: #6F6F6F;
    }
  }

  .log-list {

  }
}
</style>
  