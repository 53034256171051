<template>
  <div class="wallet-card">
    <div class="card">
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-4">
            <b-img class="w-100" thumbnail fluid :src="wallet.businessAccount.logoImg" :alt="wallet.businessAccount.name"></b-img>
          </div>

          <div class="col pl-3">
            <h5 class="font-weight-bold mb-4" @click="titleClicked">{{ wallet.businessAccount.name }}</h5>

            <div class="row justify-content-between no-gutters">
              <div class="col-12">
                <p class="h6">{{ withdrawText }}</p>
              </div>

              <div class="col-12">
                <p class="">NTD {{ wallet.points }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <b-button @click="withdrawClicked" v-if="canWithdraw">申請提領</b-button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WalletCard',
  data() {
    return {
      
    }
  },
  created() {
  },
  components: {
  },
  computed: {
    canWithdraw() {
      if (this.wallet.userCanWithdraw) {
        return true;
      }
      return false;
    },
    withdrawText() {
      return this.canWithdraw? `可提領`: `45天內店家主動匯款`;
    },
  },
  mounted() {
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    }
  },
  watch: {
    
  },
  methods: {
    titleClicked() {
      this.$emit('titleClicked', this.wallet);
    },
    withdrawClicked() {
      this.$emit('withdrawClicked', this.wallet);
    }
  }

}
</script>

<style lang="scss" scoped>
  .wallet-card {
    position: relative;

    .btn {
      background-color: #404040;
      width: 100%;
      color: #fff;
    }
  }
</style>
