<template>
  <div class="withdraw-log-card">
    <div class="card-text" @click="showModal = true">
      <p class="mb-1">{{ createdDate }}</p>
      <div class="d-flex justify-content-between">
        <p class="mb-0">
          匯款至 {{ log.bankName }}
          <span v-if="log.status === 0">(處理中)</span>
          <span v-if="log.status === 99">(取消)</span>
        </p>
        <p class="text-dark mb-0">
          <span>$ {{ log.withdrawAmount + log.handlingFee }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z" fill="#404040"/>
          </svg>
        </p>
      </div>
    </div>
    <transition name="fade">
      <FullScreenModal v-if="showModal" @back="closeModal">
        <div class="info-modal">
          <div class="container">
            <div class="sec-top">
              <h1>$ {{ log.withdrawAmount + log.handlingFee }}</h1>
              <p class="text-center mb-1">匯款至 {{ log.bankName }}</p>
              <p class="text-center mb-1">{{ createdDate }}</p>
            </div>
  
            <label for="" class="font-weight-bold">收款資訊</label>
  
            <div class="row mb-1">
              <div class="col-4">銀行名稱</div>
              <div class="col-auto">({{ log.bankCode }}) {{ log.bankName }}</div>
            </div>
  
            <div class="row mb-1">
              <div class="col-4">戶名</div>
              <div class="col-auto">{{ log.accountName }}</div>
            </div>

            <div class="row mb-1">
              <div class="col-4">帳戶</div>
              <div class="col-auto">{{ log.bankAccount }}</div>
            </div>

            <div class="row mb-1">
              <div class="col-4">手續費</div>
              <div class="col-auto">${{ log.handlingFee }}</div>
            </div>

          </div>

        </div>
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'WithdrawLogCard',
  data() {
    return {
      showModal: false,
    };
  },
  props: {
    log: {
      type: Object,
      require: true,
    }
  },
  components: {
    FullScreenModal,
  },
  computed: {
    createdDate() {
      return moment(this.log.created_at).format('YYYY/MM/DD');
    }
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.withdraw-log-card {
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-bottom: solid 1px #ddd;
  .card-text {
    padding: 0 .5rem;
    color: #6F6F6F;
  }

  .info-modal {
    color: #4D4D4D;
    .sec-top {
      padding: 3rem 0 2rem;
      h1 {
        font-size: 6rem;
        text-align: center;
      }
    }
  }
}
</style>
  