<template>
  <div class="withdraw-submit-modal">
    <div class="container">

      <div class="step-1" v-if="step === 1">
        <div>
          <p class="mb-1">可提領金額</p>
          <h1>NTD {{ wallet.points }}</h1>
          <p class="text-secondary"><small>最低提領金額：NTD {{ wallet.businessAccount.withdrawMin }}</small></p>
        </div>
        <hr/>
        <div>
          <p class="mb-3 font-weight-bold">提領方式</p>
          
          <div class="row align-items-center mb-3">
            <div class="col-12">
              <p class="mb-0">銀行代號</p>
            </div>
  
            <div class="col-12">
              <b-form-select v-model="selectedBank" :options="selectBankOpt" size="sm" class="mt-1"></b-form-select>
            </div>
          </div>
  
          <div class="row align-items-center mb-3">
            <div class="col-12">
              <p class="mb-0">戶名</p>
            </div>
  
            <div class="col-12">
              <b-input v-model="inputAccountName" placeholder="輸入銀行帳戶名" type="text" class="mt-1" />
            </div>
          </div>
  
          <div class="row align-items-center mb-3">
            <div class="col-12">
              <p class="mb-0">帳號</p>
            </div>
  
            <div class="col-12">
              <b-input v-model="inputAccount" placeholder="輸入銀行帳號" type="text" class="mt-1" />
            </div>
          </div>
        </div>
        <hr/>
        <div>
          <p class="mb-3 font-weight-bold">提領金額</p>
          <b-input v-model="inputAmount" placeholder="輸入提領金額" type="number" class="mt-2 mb-2" number/>
          <p class="text-secondary">
            <small>
              提領金額最低：NTD {{ wallet.businessAccount.withdrawMin }} 最高 NTD {{ wallet.businessAccount.withdrawMax }}。衍伸之轉帳手續費(${{ this.wallet.businessAccount.handlingFee }})將從提領金額中扣除。如使用{{ handlingFeeFreeBankName }}則免手續費。
            </small>
          </p>
        </div>
  
        <div class="mt-3">
          <b-button variant="dark" class="w-100" :disabled="withdrawBtnDisabled" @click="sendWithdrawRequest">提領</b-button>
        </div>
        
      </div>

      <div class="step-2" v-if="step === 2">
        <div class="py-3 text-center">
          <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.5 79.625C64.3467 79.625 79.625 64.3467 79.625 45.5C79.625 26.6533 64.3467 11.375 45.5 11.375C26.6533 11.375 11.375 26.6533 11.375 45.5C11.375 64.3467 26.6533 79.625 45.5 79.625ZM44.013 58.7956L62.9713 36.0456L58.362 32.2044L41.5067 52.4308L32.4547 43.3787L28.212 47.6213L39.587 58.9963L41.9099 61.3192L44.013 58.7956Z" fill="#67D8C8"/>
          </svg>

          <h3 class="text-center font-weight-bold">已完成提領申請</h3>
          <p class="text-center">店家將於指定時間完成匯款</p>

        </div>
        <hr/>
        <div>
          <div class="mb-3">
            <p class="font-weight-bold mb-1">提領金額</p>
            <p class="text-secondary mb-0">NTD {{ showExistWithdraw.withdrawAmount + showExistWithdraw.handlingFee }}</p>
          </div>

          <div class="mb-3">
            <p class="font-weight-bold mb-1">收款資訊</p>
            <div class="row mb-1">
              <div class="col-4">
                <p class="text-secondary mb-1">銀行代號</p>
              </div>
              <div class="col">
                <p class="text-secondary mb-1">({{ showExistWithdraw.bankCode }}) {{ showExistWithdraw.bankName }}</p>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-4">
                <p class="text-secondary mb-1">戶名</p>
              </div>
              <div class="col">
                <p class="text-secondary mb-1">{{ showExistWithdraw.accountName }}</p>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-4">
                <p class="text-secondary mb-1">帳戶</p>
              </div>
              <div class="col">
                <p class="text-secondary mb-1">{{ showExistWithdraw.bankAccount }}</p>
              </div>
            </div>
          </div>

          <div>
            <p>
              店家不會主動以電話或簡訊聯繫您更改付費方式及退款等。
            </p>

            <p>
              如遇問題可以使用以下方式聯繫我們：<br/>
              客服信箱：{{ this.wallet.businessAccount.email }}<br/>
              客服LINE：{{ this.wallet.businessAccount.lineId }}<br/>
              客服專線：{{ this.wallet.businessAccount.servicePhone }}
            </p>
          </div>

          <div class="mt-3">
            <b-button variant="dark" class="w-100" @click="closeBtnClick">關閉</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BankList from '@/lib/bankList.js';

export default {
  name: 'WithdrawSubmitModal',
  data() {
    return {
      readingBusyName: 'WITHDRAWSUBMITMODAL',
      savingBusyName: 'WITHDRAWSUBMITMODALSAVING',
      
      step: 1,
      selectedBank: null,
      inputAccountName: '',
      inputAccount: '',
      inputAmount: 0,
      existWithdraw: [],
      init: false,
    };
  },
  props: {
    wallet: {
      type: Object,
      require: true,
    }
  },
  components: {
  },
  computed: {
    showExistWithdraw() {
      if (this.existWithdraw.length > 0) {
        return this.existWithdraw[0];
      }
      return null;
    },
    selectBankOpt() {
      return BankList.map((b) => {
        return {
          text: `(${b.code}) ${b.name}`,
          value: b,
        };
      });
    },
    handlingFeeFreeBankName() {
      const b = BankList.find((b) => {
        return b.code === this.wallet.businessAccount.handlingFeeFreeBankCode;
      });
      if (b) {
        return b.name;
      }
      return '';
    },
    withdrawBtnDisabled() {
      if (this.selectedBank === null) {
        return true;
      }
      if (this.inputAccountName.trim().length === 0) {
        return true;
      }
      if (this.inputAccount.trim().length === 0) {
        return true;
      }
      if (isNaN(this.inputAmount)) {
        return true;
      }
      if (this.selectedBank.code !== this.wallet.businessAccount.handlingFeeFreeBankCode && this.inputAmount <= this.wallet.businessAccount.handlingFee ) {
        return true;
      }
      if (this.inputAmount < this.wallet.businessAccount.withdrawMin || this.inputAmount > this.wallet.businessAccount.withdrawMax || this.inputAmount > this.wallet.points) {
        return true;
      }
      return false;
    }
  },
  watch: {
  },
  async mounted() {
    await this.readInfo();
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    async readInfo() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        const withdrawList = await this.$store.dispatch('api/getWalletWithdrawListPromise');
        
        withdrawList.filter((w) => {
          return w.businessAccountSerial === this.wallet.businessAccount.serial && w.status === 0;
        }).map((w) => {
          this.existWithdraw.push(w);
        });

        if (this.existWithdraw.length > 0) {
          this.step = 2;
        }
        this.init = true;
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    async sendWithdrawRequest() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const requestObj = {
          businessAccountSerial: this.wallet.businessAccountSerial,
          bankCode: this.selectedBank.code,
          bankName: this.selectedBank.name,
          bankAccount: this.inputAccount.trim(),
          accountName: this.inputAccountName.trim(),
          totalWithdrawAmount: this.inputAmount,
        };
        const withdrawRequest = await this.$store.dispatch('api/postSendWithdrawRequestPromise', requestObj);
        this.existWithdraw.push(withdrawRequest);
        this.step = 2;
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    closeBtnClick() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.withdraw-submit-modal {
  padding: 1.5rem 0 1rem;
  
}
</style>
  